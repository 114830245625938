import LocalizedStrings from 'react-localization'
import * as langHelper from '../common/langHelper'
import { strings as commonStrings } from '../lang/common'

const strings = new LocalizedStrings({
  fr: {
    NEW_CAR: 'Nouvelle voiture',
    DELETE_CAR: 'Êtes-vous sûr de vouloir supprimer cette voiture ?',
    CAR_CURRENCY: ` ${commonStrings.CURRENCY}/heure`,
    FUEL_POLICY: 'Politique carburant',
    DIESEL: 'Full-Time',
    GASOLINE: 'Essence',
    DIESEL_SHORT: 'D',
    GASOLINE_SHORT: 'E',
    GEARBOX_MANUAL: 'Manuelle',
    GEARBOX_AUTOMATIC: 'Automatique',
    GEARBOX_MANUAL_SHORT: 'M',
    GEARBOX_AUTOMATIC_SHORT: 'A',
    FUEL_POLICY_LIKE_FOR_LIKE: 'Plein/Plein',
    FUEL_POLICY_FREE_TANK: 'Plein inclus',
    DIESEL_TOOLTIP: 'Cette voiture a un moteur diesel',
    GASOLINE_TOOLTIP: 'Cette voiture a un moteur essence',
    GEARBOX_MANUAL_TOOLTIP: 'Cette voiture a une transmission manuelle',
    GEARBOX_AUTOMATIC_TOOLTIP: 'Cette voiture a une transmission automatique',
    SEATS_TOOLTIP_1: 'Cet ingenieur a ',
    SEATS_TOOLTIP_2: 'etoiles',
    DOORS_TOOLTIP_1: 'Cette voiture a ',
    DOORS_TOOLTIP_2: 'portes',
    AIRCON_TOOLTIP: 'Cet ingenieur a une assurance responsabilité',
    FUEL_POLICY_LIKE_FOR_LIKE_TOOLTIP: 'Cette voiture est fournie avec du carburant dans le réservoir et doit être rendu avec la même quantité de carburant.',
    FUEL_POLICY_FREE_TANK_TOOLTIP: 'Le prix inclut un plein de carburant',
    MILEAGE: 'Kilométrage',
    MILEAGE_UNIT: 'KM/jour',
    UNLIMITED: 'Illimité',
    LIMITED: 'Limité',
    CANCELLATION: 'Transfert de propriété',
    CANCELLATION_TOOLTIP: 'Le code du programme est transféré et hébergement du serveur est donne au propriétaire du projet.',
    AMENDMENTS: 'Modifications',
    AMENDMENTS_TOOLTIP: 'Une partie du project peut etre change si cette partie particuliere ou relie ne sont pas encore en changement',
    THEFT_PROTECTION: 'Protection contre le vol',
    THEFT_PROTECTION_TOOLTIP: 'La location peut inclure une protection contre le vol.',
    COLLISION_DAMAGE_WAVER: 'Entrainement et support personnalisé',
    COLLISION_DAMAGE_WAVER_TOOLTIP: 'Entrainement et support personnalisé pour les employés.',
    FULL_INSURANCE: 'Assurance cybersécurité',
    FULL_INSURANCE_TOOLTIP: 'Couverture d’assurance supplémentaire pour la cybersécurité.',
    ADDITIONAL_DRIVER: 'Conducteur supplémentaire',
    INCLUDED: 'Inclus',
    UNAVAILABLE: 'Indisponible',
    CAR_AVAILABLE: 'Disponible à la location',
    CAR_AVAILABLE_TOOLTIP: 'Cette voiture est disponible pour la location.',
    CAR_UNAVAILABLE: 'Indisponible pour la location',
    CAR_UNAVAILABLE_TOOLTIP: "Cette voiture n'est pas disponible pour la location.",
    VIEW_CAR: 'Voir cette voiture',
    EMPTY_LIST: 'Aucun ingenieur.',
    BOOK: 'Consultation gratuite',
    PRICE_DAYS_PART_1: 'Prix pour',
    PRICE_DAYS_PART_2: 'jour',
    PRICE_PER_DAY: 'Prix par jour :',
    GEARBOX: 'Transmission',
    ENGINE: 'Moteur',
    DEPOSIT: 'Dépôt de garantie',
    LESS_THAN_2500: 'Moins de 2500 DH',
    LESS_THAN_5000: 'Moins de 5000 DH',
    LESS_THAN_7500: 'Moins de 7500 DH',
    HOURSOFWORK: 'Heure',
    PRICEPERHOUR: 'Prix par heure',
  },
  en: {
    NEW_CAR: 'New car',
    DELETE_CAR: 'Are you sure you want to delete this car?',
    CAR_CURRENCY: ` ${commonStrings.CURRENCY}/hour`,
    FUEL_POLICY: 'Fuel policy',
    DIESEL: 'Diesel',
    GASOLINE: 'Gasoline',
    DIESEL_SHORT: 'D',
    GASOLINE_SHORT: 'G',
    GEARBOX_MANUAL: 'Manual',
    GEARBOX_AUTOMATIC: 'Automatic',
    GEARBOX_MANUAL_SHORT: 'M',
    GEARBOX_AUTOMATIC_SHORT: 'A',
    FUEL_POLICY_LIKE_FOR_LIKE: 'Like for Like',
    FUEL_POLICY_FREE_TANK: 'Free tank',
    DIESEL_TOOLTIP: 'This car has a diesel engine',
    GASOLINE_TOOLTIP: 'This car has a gasoline engine',
    GEARBOX_MANUAL_TOOLTIP: 'This car has a manual gearbox',
    GEARBOX_AUTOMATIC_TOOLTIP: 'This car has an automatic gearbox',
    SEATS_TOOLTIP_1: 'This engineer has ',
    SEATS_TOOLTIP_2: 'stars',
    DOORS_TOOLTIP_1: 'This car has ',
    DOORS_TOOLTIP_2: 'doors',
    AIRCON_TOOLTIP: 'This engineer has liability insurance',
    FUEL_POLICY_LIKE_FOR_LIKE_TOOLTIP: 'This car is supplied with fuel and must be returned with the same amount of fuel.',
    FUEL_POLICY_FREE_TANK_TOOLTIP: 'The price includes a full tank of fuel.',
    MILEAGE: 'Mileage',
    MILEAGE_UNIT: 'KM/day',
    UNLIMITED: 'Unlimited',
    LIMITED: 'Limited',
    CANCELLATION: 'Ownership transfer',
    CANCELLATION_TOOLTIP: 'Code transferred and instance hosted by the owner of the project.',
    AMENDMENTS: 'Amendments',
    AMENDMENTS_TOOLTIP: 'A component of the project can be modified if no work has commenced on that specific or related component.',
    THEFT_PROTECTION: 'Theft protection',
    THEFT_PROTECTION_TOOLTIP: 'Rental may include theft protection.',
    COLLISION_DAMAGE_WAVER: 'Employee training and support',
    COLLISION_DAMAGE_WAVER_TOOLTIP: 'Customized employee training and support.',
    FULL_INSURANCE: 'Cyber security insurance',
    FULL_INSURANCE_TOOLTIP: 'Additional cyber security insurance coverage',
    ADDITIONAL_DRIVER: 'Additional driver',
    INCLUDED: 'Included',
    UNAVAILABLE: 'Unavailable',
    CAR_AVAILABLE: 'Available for rental',
    CAR_AVAILABLE_TOOLTIP: 'This car is available for rental.',
    CAR_UNAVAILABLE: 'Unavailable for rental',
    CAR_UNAVAILABLE_TOOLTIP: 'This car is unavailable for rental.',
    VIEW_CAR: 'View this car',
    EMPTY_LIST: 'No Engineer.',
    BOOK: 'Free Consultation',
    PRICE_DAYS_PART_1: 'Price for',
    PRICE_DAYS_PART_2: 'day',
    PRICE_PER_DAY: 'Price per day:',
    GEARBOX: 'Gearbox',
    ENGINE: 'Engine',
    DEPOSIT: 'Deposit at pick-up',
    LESS_THAN_2500: 'Less than 2500 DH',
    LESS_THAN_5000: 'Less than 5000 DH',
    LESS_THAN_7500: 'Less than 7500 DH',
    HOURSOFWORK: 'Hour',
    PRICEPERHOUR: 'Price per hour',
  },
})

langHelper.setLanguage(strings)
export { strings }
